import React, { Component } from 'react';
import './App.css';
import * as API from './api';

let Cookies = require('js-cookie');

export default class App extends Component {
  state = {
    token: null,
    password: '',
  };

  componentDidMount() {
    let token = Cookies.get('token');
    this.setState({
      token,
    });
  }

  setToken = (token: any) => {
    if (token) {
      Cookies.set('token', token);
    } else {
      Cookies.remove('token');
    }

    this.setState({
      token,
    });
  };

  onLogin = async () => {
    let { password } = this.state;

    const response = await fetch(`${API.ENDPOINT}login`, {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Accept-encoding': 'gzip, deflate',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ password }),
    });

    let result = await response.json();
    if (result.token) {
      this.setToken(result.token);
    }
  };

  unlockDoor = async () => {
    await API.fetchAsync('door/unlock')
  }

  render() {
    if (this.state.token) {
      return <div className="App"><button onClick={this.unlockDoor}>Unlock door</button></div>;
    } else {
      return (
        <div className="App">
          jesse
          <form
            onSubmit={(event) => {
              this.onLogin();
              event.preventDefault();
            }}>
            <input
              type="password"
              value={this.state.password}
              onChange={(event) => this.setState({ password: event.target.value })}
            />
          </form>
        </div>
      );
    }
  }
}
