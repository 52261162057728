let Cookies = require('js-cookie');

let endpoint;

if (process.env.NODE_ENV == 'development') {
  endpoint = 'http://localhost:3033/api/';
} else {
  endpoint = '/api/';
}
export const ENDPOINT = endpoint;

export async function fetchAsync(url: any, body = {}) {
  let headers: any = {
    Accept: 'application/json',
    'Accept-encoding': 'gzip, deflate',
    'Content-Type': 'application/json',
  };

  const token = Cookies.get('token');
  if (token) {
    headers['X-Auth-Token'] = token;
  }

  const response = await fetch(`${ENDPOINT}${url}`, {
    method: 'POST',
    headers,
    body: JSON.stringify(body),
  });

  try {
    return await response.json();
  } catch (e) {
    return {};
  }
}
